/** @jsx jsx */
import { jsx, Heading, Button } from "theme-ui"

import Wrapper from "./Wrapper"
import Link from "./link"

export default ({
  children,
  image,
  imageFirst,
  title,
  subtitle,
  overrideColor,
  linkLabel,
  linkUrl = "/",
  buttonVariant = "secondary",
  ...restProps
}) => {
  return (
    <Wrapper
      sx={{
        "& + &": {
          mt: ["40px", "60px"],
        },
      }}
    >
      <div
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: ["column", "column", "row"],
          justifyContent: "center",
          alignItems: "center",
        }}
        {...restProps}
      >
        <div
          sx={{
            flexGrow: 1,
            order: 1,
            textAlign: ["center", "center", image ? "left" : "center"],
            ...(image && imageFirst
              ? { pl: [0, 0, "90px"] }
              : { pr: [0, 0, "90px"] }),
            ...(!image && {
              alignSelf: "center",
              pr: 0,
              pl: 0,
            }),
          }}
          {...(!image && { variant: "centeredInner" })}
        >
          {title && (
            <Heading
              as="h2"
              sx={{
                color: overrideColor ? overrideColor : "charade",
                mb: 4,
                fontSize: ["26px", "32px", "44px"],
              }}
            >
              {title}
            </Heading>
          )}
          {subtitle && (
            <p
              sx={{
                color: overrideColor ? overrideColor : "trout",
                fontSize: ["18px"],
                position: "relative",
                pt: [0, 0, "20px"],
                mt: 0,
                mb: 0,
                ":not(:last-child)": {
                  mb: "30px",
                },
                ":before": {
                  display: ["none", "none", "block"],
                  content: '""',
                  position: "absolute",
                  top: 0,
                  width: "68px",
                  height: "2px",
                  backgroundColor: overrideColor ? overrideColor : "blueRibbon",
                },
              }}
            >
              {subtitle}
            </p>
          )}
          {linkLabel && (
            <Link to={linkUrl}>
              <Button variant={`buttons.${buttonVariant}`}>{linkLabel}</Button>
            </Link>
          )}
          <div>{children}</div>
        </div>
        {image && (
          <div
            sx={{
              flexBasis: ["100%", "52%", "52%"],
              flexShrink: 0,
              order: [0, 0, imageFirst ? 0 : 2],
              mb: ["30px", "30px", 0],
            }}
          >
            <img src={image} alt="" title="" sx={{ width: "100%" }} />
          </div>
        )}
      </div>
    </Wrapper>
  )
}
