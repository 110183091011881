/** @jsx jsx */
import { jsx, Container } from "theme-ui"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from "../components/section"
import FeatureItem from "../components/featureItem"
import Banner from "../components/banner"
import imgHero from "../images/hero.jpg"
import imgFeature_01 from "../images/features1.jpg"
import imgFeature_02 from "../images/donor1.jpg"
import imgFeature_04 from "../images/image3.jpg"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Section
      bg="linear-gradient(to right, #0072ff, #00c6ff)"
      sx={{
        color: "white",
      }}
    >
      <FeatureItem
        image={imgHero}
        title="Don’t Miss Out on Graduation"
        subtitle="Kongrads is the best way to send and receive graduation money"
        overrideColor="white"
        linkLabel="Learn More"
        linkUrl="/features"
        buttonVariant="flat"
      />
    </Section>
    <Section bg="white">
      <Container>
        <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
          <iframe
            title="Kongrads introduction"
            src="https://player.vimeo.com/video/424537645?title=0&byline=0&portrait=0"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
          ></iframe>
        </div>
      </Container>
    </Section>
    <Section
      title="Graduating Class"
      subtitle="Graduating students can easily upload their list of family members and friends and send a pre-written graduation announcement to everyone in minutes"
    >
      <FeatureItem
        image={imgFeature_01}
        title="Kongrads will get you up and running with all of your graduation announcements in 3 simple steps. It only takes a few minutes"
        linkLabel="Get Started"
        linkUrl="/app/signup"
      />
      <FeatureItem
        image={imgFeature_02}
        imageFirst
        title="Family and Friends of the Graduate"
        subtitle="Do you have a family member or friend who is graduating this year? Easily send them a custom graduation message and gift in minutes"
        // linkLabel="Get Started"
        linkUrl="/features"
      />
      <FeatureItem title="Kongrads will send a custom message and gift to your graduate(s) in a few simple steps" />
      <FeatureItem
        image={imgFeature_04}
        imageFirst
        title="No Graduation Ceremony This Year? No problem."
        subtitle="Graduates, you can still receive the love from the people who mean the most. Parents, grandparents, aunts/uncles, siblings, and friends can still show you how proud they are of your accomplishments"
      />
    </Section>
    <Banner />
  </Layout>
)

export default IndexPage