/** @jsx jsx */
import { jsx, Heading, Button } from "theme-ui"

import Section from "./section"
import Link from "./link"
import Wrapper from './Wrapper';

export default () => {
  return (
    <Section
      bg="linear-gradient(to right, #0072ff, #00c6ff)"
      sx={{
        color: "white",
      }}
    >
      <Wrapper
        sx={{
          "& + &": {
            mt: ["40px", "60px"],
          },
        }}
      >
        <div
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: ["column", "row"],
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            sx={{
              flexBasis: ["100%", "52%", "52%"],
              flexShrink: 0,
              mb: ["30px", "30px", 0],
            }}
          >
            <Heading
              as="h2"
              sx={{
                color: "white",
                mb: 4,
                fontSize: ["24px", "36px", "44px"],
              }}
            >
              Send/Receive Your Graduation Money In A Snap!
            </Heading>
            <p
              sx={{
                color: "white",
                fontSize: ["18px"],
                position: "relative",
                pt: [0, 0, "20px"],
                mb: 0,
                ":before": {
                  display: ["none", "none", "block"],
                  content: '""',
                  position: "absolute",
                  top: 0,
                  width: "68px",
                  height: "2px",
                  backgroundColor: "white",
                },
              }}
            >
              Kongrads is the best platform for graduating students and donors
              to interact.
            </p>
          </div>
          <div
            sx={{
              flexGrow: 1,
              textAlign: ["center", "right", "right"],
              pr: [0, 0, "90px"],
            }}
          >
            <Link to="/app/signup">
              <Button variant="buttons.flat">Graduates Click Here</Button>
            </Link>
          </div>
        </div>
      </Wrapper>
    </Section>
  )
}